<template>
    <v-dialog
        v-model="dialog"
        width="700"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
    >
      <v-card style="overflow-y:auto; max-height:400px;">
        <v-card-title class="primary2 py-1 px-2">
            <span class="font-size16 text-color-white">{{$t('office-selector')}}</span>
            <v-spacer />
            <v-icon small color="white" @click="$emit('false')">mdi-window-close</v-icon>
        </v-card-title>
        <v-card-text>
            <v-row class="p-5">
                <v-col cols="12">
                    <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th class="text-left">
                              <h6 class="font-size14">{{$t('name')}}</h6>
                            </th>
                            <th></th>
                            <th></th>
                          </tr>
                          </thead>
                            <tbody>
                                <tr
                                    v-for="item in userData.hospitalsAssignedData"
                                    :key="item.id"
                                    @click="selectedBranch(item)"
                                    >

                                    <td class="text-left">
                                      <h6 style="font-size:14px;">{{ item.hospitalName }}</h6>
                                    </td>

                                    <td></td>
                                      <td v-if="item.id === userData.hospitalAssignedData.id">
                                        <span class="mdi mdi-checkbox-marked float-right color-primary"></span>
                                      </td>
                                    <td v-else>
                                      <span class="mdi mdi-checkbox-blank-outline float-right color-primary" ></span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                      </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: ['dialog', 'specializations'],
	components: {
		// BirthdatePicker,
	},
	data () {
		return {
			loader: null,
			loading: false,
			valid: false,
			title: null,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
		}),
	},
	mounted () {
		// this.scaleAndTempUnit = this.userSettings.Units;
		// this.glucoseAndCholesterolUnit = this.userSettings.Concentration;
		// this.addressData();
		// this.language = this.$cookies.get('language');
	},
	methods: {
		selectedBranch (item) {
			this.$store.commit('authentication/SET_SELECTED_OFFICE', item);
			window.location.reload(true);
			this.$emit('false');
		},
	},
};
</script>
