<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    permanent
    :width="drawer ? '280' : '60'"
    v-bind="$attrs"
    color="primary2"
  >               <!-- Logo with NAME -->
    <template v-slot:prepend>
      <span class="d-flex justify-center align-center ma-6">
         <img :src="drawer ? logoWithName : logo" />
      </span>
    </template>

    <v-list
		nav
		dense
		density="compact"
    >            <!-- Item LIST with the icons -->
		<v-list-item
			:to="item.path"
			v-for="item in items"
			:key="item.title"
			:class="['layout-sider-item', drawerClass(item), {'selected-item': item.isSelected}]"
			link
			>
			<v-list-item-icon>
				<img :src="item.icon" height="18px"  />
			</v-list-item-icon>
			<v-list-item-title class="text-start text-body-2">
				<span class="white-icon">{{ item.title }}</span>
			</v-list-item-title>
		</v-list-item>
<!--      <v-select-->
<!--          style="margin-top:20px;"-->
<!--          :items="test"-->
<!--          label="Solo field"-->
<!--          dense-->
<!--          solo-->
<!--      ></v-select>-->
<!--      <div class="d-flex justify-center">-->
<!--        <v-list-item class="py-1" style="position: absolute;bottom:0;width: 290px;">-->
<!--          <v-list-item-content class="d-flex align-center elevation-1 text-center" style="border-radius: 15px; background-color:white;">-->
<!--            <span style="font-weight: bold;text-align: center">{{ userObject.userData?.hospitalAssignedData?.hospitalName }}</span>-->
<!--            <span style="text-align: center;font-size: 13px;text-transform: lowercase;">{{ userObject.userData?.hospitalAssignedData?.contactEmail }}</span>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--      </div>-->

		<div
        v-if="roleName === 'DOCTOR' || roleName === 'NURSE'"
        class="d-flex justify-center"
    >
			<v-list-item class="text-center app-drawer-select-hospital">
				<div class="select-hospital" @click="branchSelector">
<!--				<h6 class="font-weight-bold" style="font-size:15px; color:white;">{{$t('select-office-for-monitoring')}}</h6>-->
          <div v-if="drawer">
            <v-tooltip top>
              <template v-slot:activator="{on}">
                <img :src="HospitalWhite" height="25" v-on="on">
                <p class="select-hospital-name" v-on="on">{{ userObject.userData?.hospitalAssignedData?.hospitalName }}</p>
              </template>
              <h6 class="white-color">Choose Hospital</h6>
            </v-tooltip>

          </div>
          <div v-if="!drawer">
            <img :src="HospitalWhite" height="25">
          </div>
				</div>
			</v-list-item>
		</div>
		<div v-else>
		</div>

    </v-list>
	<BranchSelectorDialog
		v-if="dialog"
		:dialog="dialog"
		@false="dialog = false"
		@close="closeDialog"
	/>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
	logo,
	logoWithName,
	// dashboardBlack,
	dashboardWhite,
	// medicalTeamBlack,
  devicesWhite,
	// patientsBlack,
	usersWhite,
	doctorWhite,
  monitoringPlanPrimary,
	hospitalWhite,
	signOutBlack,
	organizationsWhite,
	billWhite,
	supportWhite,
	verifireWhite,
	mentalHealthWhite, medicalTeamWhite,
} from '@/assets';
import BranchSelectorDialog from '../dialogs/BranchSelectorDialog.vue';

export default {
	name: 'DashboardCoreDrawer',
	components: { BranchSelectorDialog },
	props: {
		expandOnHover: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		expand_reports: false,
		theme: process.env.VUE_APP_THEME,
		appVersion: process.env.VUE_APP_VERSION,
		sign_out_black: signOutBlack,
		test: ['test1', 'test2', 'test3', 'test4'],
		logoWithName: logoWithName,
		mentalHealthWhite: mentalHealthWhite,
		HospitalWhite: hospitalWhite,
    DevicesWhite: devicesWhite,
    monitoringPrimary: monitoringPlanPrimary,
    Devices: devicesWhite,
		logo: logo,
		emergencyDialog: false,
		webrtcErrorDialog: false,
		onLine: null,
		internetStatus: 'Connected',
		mini: true,
		dialog: false,
	}),
	computed: {
		...mapState(['barColor', 'barImage', 'webrtcErrors', 'pageSize']),
		...mapGetters({
			roleName: 'authentication/getRole',
			specialization: 'authentication/getSpecialization',
			pageWidth: 'pageHeight',
			userObject: 'authentication/userObject',
		}),
		drawer: {
			get () {
				return this.$store.state.drawer;
			},
			set (val) {
				this.$store.commit('SET_DRAWER', val);
			},
		},
		items () {
			switch (this?.roleName) {
				case 'SYSTEM_ADMIN':
					return [
						{
							title: this.$t('organizations'),
							icon: organizationsWhite,
							path: '/organizations',
						},
						{
							title: this.$t('hospitals'),
							icon: hospitalWhite,
							path: '/offices',
						},
						{
							title: this.$t('admins'),
							icon: usersWhite,
							path: '/admins',
						},
						{
							title: this.$t('support'),
							icon: supportWhite,
							path: '/support',
						},
						{
							title: this.$t('verifier'),
							icon: verifireWhite,
							path: '/verifier',
						},
						{
							title: this.$t('SA_Reports'),
							icon: verifireWhite,
							path: '/sareports',
						},
					];
				case 'ORGANIZATION_ADMIN':
					// this.$store.dispatch('roles/getRoles');
					this.$store.dispatch('verifiers/getVerifiersForDomainSpecialization');
					// this.$store.dispatch('verifiers/getVerifiersForDomainSurveyType');
					// this.$store.dispatch('verifiers/getVerifiersForDomainMentalHealth');

					if (this.userObject.userData?.organizationAssignedData?.wellnessFlagDefault) {
						return [
							{
								title: this.$t('Organizations'),
								icon: organizationsWhite,
								path: '/organizations',
							},
							{
								title: this.$t('hospitals'),
								icon: hospitalWhite,
								path: '/offices',
							},
							{
								title: this.$t('admins'),
								icon: usersWhite,
								path: '/admins',
							},
							{
								title: this.$t('staff'),
								icon: doctorWhite,
								path: '/medicalstaff',
							},
							// {
							// 	title: this.$t('patients'),
							// 	icon: usersWhite,
							// 	path: '/patients',
							// },
							// {
							// 	title: this.$t('OA_Reports'),
							// 	icon: verifireWhite,
							// 	path: '/oareports',
							// },
							// {
							// 	title: this.$t('medicalTeams'),
							// 	icon: medicalTeamWhite,
							// 	path: '/medicalteam',
							// },
							{
								title: this.$t('billings'),
								icon: billWhite,
								path: '/billings',
							},
							// how to show or hide this based on condition
							{
								title: this.$t('monitoring-plans'),
								icon: devicesWhite,
								path: '/monitoring',
							},
						];
					} else {
						return [
							{
								title: this.$t('Organizations'),
								icon: organizationsWhite,
								path: '/organizations',
							},
							{
								title: this.$t('hospitals'),
								icon: hospitalWhite,
								path: '/offices',
							},
							{
								title: this.$t('admins'),
								icon: usersWhite,
								path: '/admins',
							},
							{
								title: this.$t('staff'),
								icon: doctorWhite,
								path: '/medicalstaff',
							},
							// {
							// 	title: this.$t('patients'),
							// 	icon: usersWhite,
							// 	path: '/patients',
							// },
							// {
							// 	title: this.$t('OA_Reports'),
							// 	icon: verifireWhite,
							// 	path: '/oareports',
							// },
							// {
							// 	title: this.$t('medicalTeams'),
							// 	icon: medicalTeamWhite,
							// 	path: '/medicalteam',
							// },
							{
								title: this.$t('billings'),
								icon: billWhite,
								path: '/billings',
							},
							// {
							// 	title: this.$t('monitoring-plans'),
							// 	icon: devicesWhite,
							// 	path: '/monitoring',
							// },
						];
					}
				case 'HOSPITAL_ADMIN':
					this.$store.dispatch('roles/getRoles');
					this.$store.dispatch('verifiers/getVerifiersForDomainSpecialization');
					this.$store.dispatch('verifiers/getVerifiersForDomainSurveyType');
					this.$store.dispatch('verifiers/getVerifiersForDomainMentalHealth');
					return [
						{
							title: this.$t('staff'),
							icon: doctorWhite,
							path: '/medicalstaff',
						},
						{
							title: this.$t('patients'),
							icon: usersWhite,
							path: '/patients',
						},
						{
							title: this.$t('medicalTeams'),
							icon: medicalTeamWhite,
							path: '/medicalteam',
						},
						{
							title: this.$t('billings'),
							icon: billWhite,
							path: '/billings',
						},
						{
							title: this.$t('assessments'),
							icon: mentalHealthWhite,
							path: '/wellnessassessments',
						},
						// {
						// 	title: this.$t('test'),
						// 	icon: mentalHealthWhite,
						// 	path: '/mentalhealthtemplate',
						// },

					];
				case 'DOCTOR':
					this.$store.dispatch('roles/getRoles');
					this.$store.dispatch('verifiers/getVerifiersForDomainSpecialization');
					this.$store.dispatch('verifiers/getVerifiersForDomainSurveyType');
					this.$store.dispatch('verifiers/getVerifiersForDomainMentalHealth');

					return [
							{
								title: this.$t('dashboardIconTooltip'),
								icon: dashboardWhite,
								path: '/dashboard',
							},
							{
								title: this.$t('staff'),
								icon: doctorWhite,
								path: '/medicalstaff',
							},
							{
								title: this.$t('patients'),
								icon: usersWhite,
								path: '/patients',
							},
							{
								title: this.$t('medicalTeams'),
								icon: medicalTeamWhite,
								path: '/medicalteam',
							},
							{
								title: this.$t('billings'),
								icon: billWhite,
								path: '/billings',
							},
							{
								title: this.$t('assessments'),
								icon: mentalHealthWhite,
								path: '/wellnessassessments',
							},
							// {
							// 	title: this.$t('mental-health-assignments'),
							// 	icon: mentalHealthWhite,
							// 	path: '/mentalhealthpatient',
							// },
						];
				default:
					if (this.specialization?.doctorMetadataReadDto) {
						return [
							{
								title: this.$t('dashboardIconTooltip'),
								icon: dashboardWhite,
								path: '/dashboard',
							},
							{
								title: this.$t('medicalTeam'),
								icon: doctorWhite,
								path: '/medicalteam',
							},
							{
								title: this.$t('patients'),
								icon: usersWhite,
								path: '/patients',
							},
							// {
							// 	title: this.$t('mental-health-template'),
							// 	icon: brainWhite,
							// 	path: '/mentalhealthtemplate',
							// },
							// {
							// 	title: this.$t('mental-health-assignments'),
							// 	icon: mentalHealthWhiteAssign,
							// 	path: '/mentalhealthpatient',
							// },
						];
					} else {
						return [
							{
								title: this.$t('dashboardIconTooltip'),
								icon: this.$route.name.split(' ').join('').toLowerCase() === 'dashboard' ? dashboardWhite : dashboardWhite,
								path: '/dashboard',
							},
							{
								title: this.$t('medicalTeam'),
								icon: this.$route.name.split(' ').join('').toLowerCase() === 'medicalteam' ? doctorWhite : doctorWhite,
								path: '/medicalteam',
							},
							{
								title: this.$t('patients'),
								icon: this.$route.name.split(' ').join('').toLowerCase() === 'patients' || this.$route.name === 'Patient Details' ? usersWhite : usersWhite,
								path: '/patients',
							},
						];
					}
			}
		},
	},
	watch: {
		pageSize (val) {
			if (val.width < 800 && val) { this.drawer = false; } else if (val.width >= 800 && val === false) { this.drawer = true; }
		},
	},
	mounted () {
	},
	methods: {
		logout () {
			this.$router.push({ name: 'Login' });
		},
		drawerClass (item) {
			const classes = [];
			if (['/mentalhealthpatient'].includes(item.path)) {
				// Check if the current route path matches one of the specified paths
				if (this.$route.path === item.path) {
				classes.push('selected-item');
				}
			}
			if (item.path === '/dashboard') {
				if (item.path.substring(1) === this.$route.name.toLowerCase()) {
					classes.push('layout-sider-item-active-dashboard');
					classes.push('selected-item');
				} else {
					classes.push('layout-sider-item-deactive');
				}
			} else {
				if (this.$route.name === 'Patient Details') {
					if (item.path.substring(1) === 'patients') {
						classes.push('layout-sider-item-active-dashboard');
						classes.push('selected-item');
					} else {
						classes.push('layout-sider-item-deactive');
					}
				} else if (['/wellnessassessments'].includes(item.path)) {
					// Check if the current route path matches one of the specified paths
					if (this.$route.path === item.path) {
						classes.push('selected-item');
					}
				} else if (['/organizations', '/offices', '/oareports', '/sareports'].includes(item.path)) {
					// Check if the current route path matches one of the specified paths
					if (this.$route.path === item.path) {
						classes.push('selected-item');
					}
				} else {
					if (item.path.substring(1) === this.$route.name.split(' ').join('').toLowerCase()) {
						classes.push('layout-sider-item-active');
						classes.push('selected-item');
					} else {
						classes.push('layout-sider-item-deactive');
					}
				}
			}
			return classes.join(' ');
		},
		iconClass (item) {
			if (this.$route.name === 'Patient Details') {
				return item.path.substring(1) === 'patients' ? 'icon-active' : 'icon-deactive';
			}
			const iconClass = item.path.substring(1) === this.$route.name.split(' ').join('').toLowerCase() ? 'icon-active' : 'icon-deactive';
			return iconClass;
		},
		titleClass (item) {
			if (this.$route.name === 'Patient Details') {
				return item.path.substring(1) === 'patients' ? 'white--text' : 'white--text';
			}
			return item.path.substring(1) === this.$route.name.split(' ').join('').toLowerCase() ? 'white--text' : 'white--text';
		},
		branchSelector () {
			// this.dialog = true;
			if (this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				this.dialog = true;
			} else {
				this.dialog = false;
			}
		},
		closeDialog () {
			this.dialog = false;
		},
	},
};
</script>

<style>
</style>
